*{
    margin: 0;
    padding: 0;
}
body{
    width: 100%;
    height: 100%;
    background-image: url('./Imagenes/Fondo.png');
    background-repeat: no-repeat;
}
.contenedor{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;    
}
.contenido {
    margin-top: 2%;
    margin-left: 10%;
    width: 80%;
    display: flex;
    flex-direction: column;
}
.contenido h3{
    color: white;
    font-size: 36px;
    text-align: center;
}
.contenido .animate {
    margin-top: 1%;
    width: 66%;
    margin-left: 17%;
    margin-bottom: 1%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.contenido h4{
    color: white;
    font-size: 24px;
    text-align: center;
    margin-bottom: 30px;
}

@media screen and (min-width: 320px) and (max-width: 400px){
    .contenido {
        margin-top: 10%;        
    }    
    .contenido h3{
        font-size: 18px;        
    }
    .contenido .animate {
        width: 100%;
        margin-bottom: 5%;
        margin-left: 0;
    }
    .contenido h4{
        font-size: 16px;
        text-align: center;
    }
}
@media screen and (min-width: 400.1px) and (max-width: 460px){
    .contenido {
        margin-top: 10%;        
    }    
    .contenido h3{
        font-size: 22px;        
    }
    .contenido .animate {
        width: 100%;
        margin-bottom: 5%;
        margin-left: 0;
    }
    .contenido h4{
        font-size: 18px;
        text-align: center;
    }
}

@media screen and (min-width: 460.1px) and (max-width: 520px){
    .contenido {
        margin-top: 10%;        
    }    
    .contenido h3{
        font-size: 24px;        
    }
    .contenido .animate {
        width: 100%;
        margin-bottom: 5%;
        margin-left: 0;
    }
    .contenido h4{
        font-size: 20px;
        text-align: center;
    }
}
@media screen and (min-width: 520.1px) and (max-width: 600px){
    .contenido {
        margin-top: 10%;        
    }    
    .contenido h3{
        font-size: 26px;        
    }
    .contenido .animate {
        width: 100%;
        margin-bottom: 5%;
        margin-left: 0;
    }
    .contenido h4{
        font-size: 22px;
        text-align: center;
    }
}

@media screen and (min-width: 600.1px) and (max-width: 700px){
    .contenido {
        margin-top: 10%;        
    }    
    .contenido h3{
        font-size: 28px;        
    }
    .contenido .animate {
        width: 100%;
        margin-bottom: 5%;
        margin-left: 0;
    }
    .contenido h4{
        font-size: 24px;
        text-align: center;
    }
}
@media screen and (min-width: 700.1px) and (max-width: 800px){
    .contenido {
        margin-top: 5%;        
    }    
    .contenido h3{
        font-size: 30px;        
    }
    .contenido .animate {
        width: 100%;
        margin-bottom: 3%;
        margin-left: 0;
    }
    .contenido h4{
        font-size: 26px;
        text-align: center;
    }
}
@media screen and (min-width: 800.1px) and (max-width: 900px){
    .contenido {
        margin-top: 5%;        
    }    
    .contenido h3{
        font-size: 32px;        
    }
    .contenido .animate {
        width: 100%;
        margin-bottom: 3%;
        margin-left: 0;
    }
    .contenido h4{
        font-size: 28px;
        text-align: center;
    }
}
@media screen and (min-width: 900.1px) and (max-width: 1150px){
    body{
        background-repeat: repeat-y;
    }
    .contenido {
        margin-top: 5%;        
    }    
    .contenido h3{
        font-size: 34px;        
    }
    .contenido .animate {
        width: 90%;
        margin-bottom: 3%;
        margin-left: 5%;
    }
    .contenido h4{
        font-size: 30px;
        text-align: center;
    }
}